<template>
    <Form @submit="handleSubmit" v-slot="{ errors }" class="forms-setting-form" ref="profile-form">
        <div class="content_area">
            <div class="content_wpr">
                <div class="container">
                    <div class="section_content w-100" v-if="billingProfileLoader">
                        <quote-loader />
                    </div>
                    <div class="section_content w-100" v-else>
                        <div class="section_header">
                            <h2>Personal Info <span>Update your photo and personal details here.</span></h2>
                        </div>
                        <div class="billing">
                            <div class="setting_wpr">
                                <div class="profile_top">
                                    <div class="user_img">
                                        <img :src="form.avatar ? form.avatar : require('@/assets/images/male-female.png')" alt="">
                                        <image-upload v-model="form.avatar" image-type="coach-logo" :is-avatar="true" :is-button="true" :is-icon="true" icon="fas fa-pencil-alt" upload-text="Avatar" v-if="isEdit" />
                                    </div>
                                    <button type="button" class="btn" @click="editProfile()" v-if="!isEdit">Edit Info</button>
                                </div>
                                <div class="border-bottom py-4">
                                    <div class="form_grp">
                                        <div class="group_item">
                                            <label class="input_label">Full Name</label>
                                            <div class="field_wpr" v-if="isEdit" :class="{ 'has-error': errors.full_name }">
                                                <Field autocomplete="off" type="text" name="full_name" v-model="form.full_name" placeholder="ex: Robert Bacins" />
                                            </div>
                                            <h4 v-else>{{ form.full_name ? form.full_name : '-' }}</h4>
                                            <ErrorMessage name="full_name" class="text-danger" />
                                        </div>
                                    </div>
                                    <div class="form_grp">
                                        <div class="group_item">
                                            <label class="input_label">Email</label>
                                            <div class="field_wpr" v-if="isEdit" :class="{ 'has-error': errors.email }">
                                                <Field autocomplete="off" type="email" name="email" v-model="form.email" placeholder="ex: hello.robert@onboardme.io" />
                                            </div>
                                            <h4 v-else>{{ form.email ? form.email : '-' }}</h4>
                                            <ErrorMessage name="email" class="text-danger" />
                                        </div>
                                    </div>
                                    <div class="form_grp">
                                        <div class="group_item">
                                            <label class="input_label">Phone</label>
                                            <div class="field_wpr" v-if="isEdit" :class="{ 'has-error': errors.phone }">
                                                <Field autocomplete="off" type="text" name="phone" v-model="form.phone" placeholder="ex: 18433439321" />
                                            </div>
                                            <h4 v-else>{{ form.phone ? form.phone : '-' }}</h4>
                                            <ErrorMessage name="phone" class="text-danger" />
                                        </div>
                                    </div>
                                </div>
                                <div class="border-bottom py-4">
                                    <div class="form_grp">
                                        <div class="group_item">
                                            <label class="input_label">Billing Address</label>
                                            <div class="field_wpr" v-if="isEdit" :class="{ 'has-error': errors.billing_address }">
                                                <Field autocomplete="off" v-model="form.billing_address" name="billing_address">
                                                    <textarea cols="30" rows="10" v-model="form.billing_address" placeholder="ex: 123 Anywhere Street."></textarea>
                                                </Field>
                                            </div>
                                            <h4 v-else>{{ form.billing_address ? form.billing_address : '-' }}</h4>
                                            <ErrorMessage name="billing_address" class="text-danger" />
                                        </div>
                                    </div>
                                    <div class="form_grp">
                                        <div class="group_item">
                                            <label class="input_label">City</label>
                                            <div class="field_wpr" v-if="isEdit" :class="{ 'has-error': errors.billing_city }">
                                                <Field autocomplete="off" type="text" name="billing_city" v-model="form.billing_city" placeholder="ex: San Francisco" />
                                            </div>
                                            <h4 v-else>{{ form.billing_city ? form.billing_city : '-' }}</h4>
                                            <ErrorMessage name="billing_city" class="text-danger" />
                                        </div>
                                        <div class="group_item">
                                            <label class="input_label">State/Province</label>
                                            <div class="field_wpr" v-if="isEdit" :class="{ 'has-error': errors.billing_state }">
                                                <Field autocomplete="off" type="text" name="billing_state" v-model="form.billing_state" placeholder="ex: California" />
                                            </div>
                                            <h4 v-else>{{ form.billing_state ? form.billing_state : '-' }}</h4>
                                            <ErrorMessage name="billing_state" class="text-danger" />
                                        </div>
                                    </div>
                                    <div class="form_grp">
                                        <div class="group_item">
                                            <label class="input_label">Postal Code</label>
                                            <div class="field_wpr" v-if="isEdit" :class="{ 'has-error': errors.billing_zipcode }">
                                                <Field autocomplete="off" type="text" name="billing_zipcode" v-model="form.billing_zipcode" placeholder="ex: 94116" />
                                            </div>
                                            <h4 v-else>{{ form.billing_zipcode ? form.billing_zipcode : '-' }}</h4>
                                            <ErrorMessage name="billing_zipcode" class="text-danger" />
                                        </div>
                                        <div class="group_item">
                                            <label class="input_label">Country</label>
                                            <div class="field_wpr" v-if="isEdit" :class="{ 'has-error': errors.billing_country }">
                                                <Field autocomplete="off" name="billing_country" v-model="form.billing_country" :class="{ 'has-error': errors.billing_country }">
                                                    <multiselect
                                                        v-model="form.billing_country"
                                                        :options="countries"
                                                        value-prop="code"
                                                        label="country"
                                                        :searchable="true"
                                                        placeholder="Select country"
                                                        autocomplete="nofill"
                                                    ></multiselect>
                                                </Field>
                                            </div>
                                            <h4 v-else>{{ form.billing_country ? form.billing_country : '-' }}</h4>
                                            <ErrorMessage name="billing_country" class="text-danger" />
                                        </div>
                                    </div>
                                </div>
                                <div class="py-4">
                                    <div class="form_grp">
                                        <div class="group_item">
                                            <label class="input_label">
                                                <span>Shipping Address</span>
                                                <label for="is-same-address" class="switch_option capsule_btn p-0" v-show="isEdit">
                                                    <h5 class="fs-14">Same as Billing Address</h5>
                                                    <input type="checkbox" id="is-same-address" v-model="form.is_address_same" :true-value="1" :false-value="0" hidden>
                                                    <div><span></span></div>
                                                </label>
                                            </label>
                                            <div class="field_wpr" v-if="isEdit" :class="{ 'has-error': errors.shipping_address }">
                                                <Field autocomplete="off" v-model="form.shipping_address" name="shipping_address" :disabled="form.is_address_same">
                                                    <textarea cols="30" rows="10" v-model="form.shipping_address" placeholder="ex: 123 Anywhere Street." :disabled="form.is_address_same"></textarea>
                                                </Field>
                                            </div>
                                            <h4 v-else>{{ form.shipping_address ? form.shipping_address : '-' }}</h4>
                                            <ErrorMessage name="shipping_address" class="text-danger" />
                                        </div>
                                    </div>
                                    <div class="form_grp">
                                        <div class="group_item">
                                            <label class="input_label">City</label>
                                            <div class="field_wpr" v-if="isEdit" :class="{ 'has-error': errors.shipping_city }">
                                                <Field autocomplete="off" type="text" name="shipping_city" v-model="form.shipping_city" placeholder="ex: San Francisco" :disabled="form.is_address_same" />
                                            </div>
                                            <h4 v-else>{{ form.shipping_city ? form.shipping_city : '-' }}</h4>
                                            <ErrorMessage name="shipping_city" class="text-danger" />
                                        </div>
                                        <div class="group_item">
                                            <label class="input_label">State/Province</label>
                                            <div class="field_wpr" v-if="isEdit" :class="{ 'has-error': errors.shipping_state }">
                                                <Field autocomplete="off" type="text" name="shipping_state" v-model="form.shipping_state" placeholder="ex: California" :disabled="form.is_address_same" />
                                            </div>
                                            <h4 v-else>{{ form.shipping_state ? form.shipping_state : '-' }}</h4>
                                            <ErrorMessage name="shipping_state" class="text-danger" />
                                        </div>
                                    </div>
                                    <div class="form_grp">
                                        <div class="group_item">
                                            <label class="input_label">Postal Code</label>
                                            <div class="field_wpr" v-if="isEdit" :class="{ 'has-error': errors.shipping_zipcode }">
                                                <Field autocomplete="off" type="text" name="shipping_zipcode" v-model="form.shipping_zipcode" placeholder="ex: 94116" :disabled="form.is_address_same" />
                                            </div>
                                            <h4 v-else>{{ form.shipping_zipcode ? form.shipping_zipcode : '-' }}</h4>
                                            <ErrorMessage name="shipping_zipcode" class="text-danger" />
                                        </div>
                                        <div class="group_item">
                                            <label class="input_label">Country</label>
                                            <div class="field_wpr" v-if="isEdit" :class="{ 'has-error': errors.shipping_country }">
                                                <Field autocomplete="off" name="shipping_country" v-model="form.shipping_country" :class="{ 'has-error': errors.shipping_country }">
                                                    <multiselect
                                                        v-model="form.shipping_country"
                                                        :options="countries"
                                                        value-prop="code"
                                                        label="country"
                                                        :searchable="true"
                                                        placeholder="Select country"
                                                        autocomplete="nofill"
                                                        :disabled="form.is_address_same ? true: false"
                                                    ></multiselect>
                                                </Field>
                                            </div>
                                            <h4 v-else>{{ form.shipping_country ? form.shipping_country : '-' }}</h4>
                                            <ErrorMessage name="shipping_country" class="text-danger" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="action_wpr" v-if="isEdit">
            <button :disabled="billingProfileSaveLoader" type="button" class="btn cancel_btn" @click="isEdit = false">Cancel</button>
            <button :disabled="billingProfileSaveLoader" class="btn save_btn">
                <i class="fa fa-spinner fa-spin" v-if="billingProfileSaveLoader"></i>
                {{ billingProfileSaveLoader ? 'Saving' : 'Save' }}
            </button>
        </div>
    </Form>
</template>

<script>
    import { defineAsyncComponent } from 'vue'
    import { Field, Form, ErrorMessage } from 'vee-validate'
    import { mapState, mapActions } from 'vuex'

    const ImageUpload = defineAsyncComponent(() => import('@/components/image-library/ImageUpload'))

    import Multiselect from '@vueform/multiselect'

    export default {
        data () {
            return {
                isEdit: false,
                form: {
                    full_name: '',
                    email: '',
                    phone: '',
                    billing_address: '',
                    billing_city: '',
                    billing_state: '',
                    billing_zipcode: '',
                    billing_country: '',
                    is_address_same: 0,
                    shipping_address: '',
                    shipping_city: '',
                    shipping_state: '',
                    shipping_zipcode: '',
                    shipping_country: '',
                },
            };
        },

        components: {
            Form,
            Field,
            ErrorMessage,
            Multiselect,
            ImageUpload,
        },

        watch: {
            billingProfile (profile) {
                const vm = this;

                vm.form = JSON.parse(JSON.stringify(profile));
            },

            'form.is_address_same' (val) {
                const vm = this;

                if (val) {
                    vm.form.shipping_address  = vm.form.billing_address;
                    vm.form.shipping_city     = vm.form.billing_city;
                    vm.form.shipping_state    = vm.form.billing_state;
                    vm.form.shipping_zipcode  = vm.form.billing_zipcode;
                    vm.form.shipping_country  = vm.form.billing_country;
                } else {
                    vm.form.shipping_address  = '';
                    vm.form.shipping_city     = '';
                    vm.form.shipping_state    = '';
                    vm.form.shipping_zipcode  = '';
                    vm.form.shipping_country  = '';
                }
            },
        },

        computed: mapState({
            user: state => state.authModule.user,
            countries: state => state.commonModule.countries,
            billingProfile: state => state.billingModule.billingProfile,
            billingProfileLoader: state => state.billingModule.billingProfileLoader,
            billingProfileSaveLoader: state => state.billingModule.billingProfileSaveLoader,
        }),

        mounted () {
            const vm = this;

            if (vm.countries.length == 0) {
                vm.getCountries();
            }

            if (!vm.billingProfile.id) {
                vm.getBillingProfile();
            } else {
                vm.form = JSON.parse(JSON.stringify(vm.billingProfile));
            }
        },

        methods: {
            ...mapActions({
                getCountries: 'commonModule/getCountries',
                getBillingProfile: 'billingModule/getBillingProfile',
                updateBillingProfile: 'billingModule/updateBillingProfile',
            }),

            editProfile () {
                const vm = this;

                this.isEdit = true;
            },

            handleSubmit (form, { setFieldError }) {
                const vm = this;
                const params = vm.form;
                      params.setFieldError = setFieldError;

                vm.updateBillingProfile(params).then((result) => {
                    if (result) {
                        vm.isEdit = false;
                    }
                });
            },
        },
    }
</script>

<style scoped>
    .container{
        max-width: 700px;
        width: 100%;
        background: #fff;
        margin: 0 auto;
        padding: 15px;
        border-radius: 8px;
    }
    :deep(.button-uploader) {
        width: 20px;
        height: 20px;
        font-size: 8px;
        border-radius: 50%;
        background: #fff;
        border: 1px solid #dbdbdb;
        color: #2f7eed;
        position: absolute;
        right: 0;
        bottom: 0;
        cursor: pointer;
    }

    :deep(.button-uploader .image_setting) {
        height: 20px;
        width: 20px;
        position: relative;
    }

    :deep(.button-uploader .upload_btn) {
        left: 0;
        top: 0;
    }

    :deep(.button-uploader .upload_btn .select_img) {
        height: 18px;
        width: 18px;
        border-radius: 50%;
        text-align: center;
        line-height: 20px;
        cursor: pointer;
    }

    :deep(.cropper-canvas img) {
        border-radius: 0px !important;
    }

    :deep(.cropper-model .action_wpr button) {
        position: relative;
    }

    :deep(.cropper-model .action_wpr button.btn) {
        min-width: 100px;
        height: 40px;
        border: 1px solid #2f80ed;
        background: #2f80ed;
        border-radius: 4px;
        margin-left: 10px;
        font-size: 14px;
        line-height: 30px;
        color: #fff;
        text-align: center;
        padding: 0 30px;
        box-sizing: border-box;
        font-weight: 500;
        font-family: Inter,sans-serif;
        cursor: pointer;
        text-transform: uppercase;
        letter-spacing: 0.5px;
    }

    :deep(.cropper-model .action_wpr button.cancel_btn) {
        background: transparent;
        color: #121525;
        font-weight: 400;
        border: 1px solid #dbdde1;
    }

    .section_content .quote_wpr {
        min-height: 90vh;
        justify-content: center;
    }
</style>
